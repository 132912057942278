export default {
	italian: "Italiano",
	english: "Inglés",
	japanese: "Japonés",
	french: "Francés",
	german: "Alemán",
	spanish: "Español",
	general: {
		name_company: "Teaming Labs",
		back: "Atrás",
		go: "Ir",
		next: "Siguiente",
		team: "Equipo",
		round: "Ronda",
		ranking: "Ranking",
		results: "Resultados",
		total: "Total",
		remaining: "Restante",
		answer: "Responder",
		units: "Unidades",
		shop: "Tienda",
		ingredients: "Ingredientes",
		rices: "Arroces",
		plates: "Platos",
		choosing: "Eligiendo",
		save: "Guardar",
		cancel: "Cancelar",
		name: "Nombre",
		buy: "Comprar",
		photo: "Foto",
		redo: "Rehacer",
		password: "Contraseña",
		exit: "Salir",
		canceled: "Cancelado",
		event: "Evento",
		day: "Día",
		ingredient: "Ingrediente",
		points: "Puntos",
		position: "Posición",
		agency: "Distribuidor",
		delete: "Eliminar",
		updated: "actualizado",
		created: "creado",
		update: "actualizar",
		create: "crear",
		filters: "Filtros",
		add:"Añadir",
		// TO CHECK 
		start:"Empezar",
		from:"Hasta",
		error:"Error",
		chat:"Chat",
		game_master:"STAFF",
		options:"Opciones",
		team_name:"Nombre de equipo",
		option_organizer:"Opciones staff",
		btn_send_answer:"Enviar respuesta",
		btn_continue:"Continuar",
		correct_answer:"Respuesta correcta",
		incorrect_answer:"Respuesta incorrecta",
		challenge_complete:"Reto completado",
		instructor: "Monitor",
		close:"Cerrar",
		edit: "Editar",
		accept: "Aceptar",
		unsaved_changes: "Hay cambios sin guardar",
		en: "Inglés",
		es: "Español",
		yes: "Si",
		no: "No",
		ipad_title: "Scavenger Hunt App",
		tapas_title: "Culinary App",
		utq_title: "Rally App",
		action_title: "Contest App",
		hours: "Horas",
		minutes: "Minutos",
		new: "Nuevo",
		mark_all_read: "Marcar todo como leido",
		name_app: "TEAMING APP",
		hello: "Hola",
		reload_app:"Recargar APP",
		change_monitor: "Cambiar monitor",
		must: "Obligatorio",
		first: "Primero",

		// ERORROS
		try_again:"Error, vuelve a intentarlo",
		incorrect_fields:"Hay campos incorrectos en el formulario",

		// Success
		save_changed:"Cambio guardado",
		ranking_block:"El administrador ha ocultado el ranking",

		// FORM GENERATOR
		answer_correct: "Respuesta correcta",
		answer_incorrect: "Respuesta incorrecta",
		pair_a: "Pareja A ",
		pair_b: "Pareja B ",
		order_colon: "Orden: ",
		quiz_multi: "Cuestionario multiple",
		answer_auto: "Respuesta autocorregida",
		order: "Order",
		click_add: "Click para añadir",
		click_upload: "Click para subir",
		quiz: "Cuestionario",
		pairs: "Parejas",
		min_answers: "Tiene que haber un minimo de {min} respuestas",
		max_answers: "El limite de respuestas son {max}",
		file_large: "Archivo demasiado pesado el maximo son 50MB",
		new_item: "Nuevo item",
		new_contact: "Nuevo contacto",
		add_item: "Añade un item",
		add_contact: "Añade un contacto",
		missing_items: "No hay ningun item añadido",
		missing_contacts: "No hay ningun contacto añadido",
		select_item: "Selecciona un item",
		select_contact: "Selecciona un contacto",
		total_price: "Total {total_price}€",
		app_activity_plus: "+ App Actividad",
		app_activity: "App Actividad",
		url_admin: "URL Admin",
		url_gm: "URL Monitor",
		preview:"Previsualización",
		actions: "Acciones",
		challenges: "Retos",
		all: "Todos",
		demo_upper:"DEMO",
		invalid_url: "La URL no es válida",
	},
	menu: {
		sales: "Ventas",
		budgets: "Presupuestos",
		clients: "Clientes",
		bookings: "Reservas",
		booking_list: "Lista de reservas",
		calendar: "Calendario",
		activities: "Actividades",
		demos: "Demos",
		content: "Contenido",
		settings: "Plantillas",
		settings_erp: "Configuración",
		organization: "Organización",
		activities_erp: "Catálogo",
		extras: "Página extras",
		final_page_budget: "Página final presupuesto",
		activities_questions: "Preguntas actividades",
		notifications: "Notificaciones",
		logout: "Cerrar sesión",
	},
	budgets: {
		budgets: "Presupuestos",
		status: "Estado",
		search: "Buscar",
		search_name: "Buscar por nombre",
		name: "Nombre",
		client: "Cliente",
		people: "Personas",
		date: "Fecha del evento",
		open: "Abierto",
		won: "Ganado",
		lost: "Perdido",
		all: "Todos",
		new_budget: "Nuevo Presupuesto",
		must_name: "Debe tener un nombre",
		budget_validity:"Validez del presupuesto",
		hour: "Hora",
		show_total: "Mostrar total",
		language: "Idioma",
		spanish: "Español",
		english: "Ingles",
		conditions: "Condiciones",
		extras: "Extras",
		budget: "Presupuesto",
		close_budget: "Cerrar Presupuesto",
		download: "Descargar",
		select_option: "Selecciona una opción",
		select_items: "Selecciona los items que se transferiran a la reserva",
		comments_close_budget: "Comentarios sobre el cierre del presupuesto",
		convert: "Convertir",
		close: "Cerrar",
		back: "Volver",
		cancel: "Cancelar",
		line: "Añadir línea",
		auto_price: "Precio Automatico",
		total: "Total",
		description: "Descripción",
		price_tag: "Tag precio",
		pax: "Personas",
		price: "Precio",
		user: "Usuario",
		no_condition: "Sin condiciones",
		comments: "Comentarios",
		activity: "Actividad",
		location: "Ubicación",
		item: "Item",
		first: "Primero",
		close_convert: "Cerrar/Convertir presupuesto",
	},
	bookingmedia:{
		uploadImages: "Subir imágenes",
		downloadImages: "Descargar imágenes",
		imagesUploaded: "Imagen subida",
		imageRemoved: "Imagen eliminada",
		uploadFailed:"Error al subir la imagen",
		notSavedForm:"Debes guardar la reserva antes de subir imágenes",

	},
	bookings: {
		
		general: "General",
		event: "Evento",
		questions: "Preguntas",
		economics: "Economics",
		ratings: "Valoraciones",
		bookings: "Reservas",
		media: "Media",
		name: "Nombre",
		placeholder_name: "Nombre",
		alert_name: "Debe tener un nombre",
		client: "Cliente",
		people: "Personas",
		date: "Fecha",
		search_name: "Buscar por nombre",
		search: "Buscar",
		new_booking: "Nueva Reserva",
		language: "Idioma",
		hour: "Hora actividad",
		client_logo: "Logo del cliente",
		billing_data: "Datos de facturación (Nombre empresa, NIF y dirección)",
		activities_app: "Actividades App",
		type_event: "Tipo de evento",
		place: "Ubicación",
		url_location: "Enlace GMaps",
		place_information: "Información del espacio",
		location: "Ubicación",
		must_name: "Debe tener un nombre",
		general_information: "Información general",
		extra_information: "Información extra",
		extra_information_internal: "Información extra interna",
		timeline: "Horario",
		description: "Descripción",
		add_activity: "Añadir actividad",
		number_teams: "Numero de equipos",
		staff_people: "Nº de monitores",
		teams: "Equipos",
		staff: "Monitores",
		add_team: "Añadir equipo",
		add_staff: "Añadir monitor",
		contact_person: "Persona de contacto",
		add_person_contact: "Añadir persona de contacto",
		add_item: "Anade un item",
		new_contact: "Nuevo contacto",
		add_questions: "Añadir Preguntas",
		modify_questions: "Modificar preguntas",
		add: "Añadir",
		all_questions: "Todas las preguntas",
		activity_questions: "Preguntas de la actividad",
		costs: "Costes",
		price: "Precio",
		activity: "Actividad",
		reserve_item: "Servicios",
		gm_pay: "Costes de staff",
		add_gm: "Añadir Monitor",
		game_master: "Monitor",
		salary: "Salario",
		total: "Total",
		gm_valoration: "Valoración del game master",
		client_valoration: "Valoración del cliente",
		es: "Español",
		en: "Inglés",
		cat: "Català",
		email: "Email",
		phone: "Telefono",
		extra: "Extra",
		online: "Online",
		in_person: "Presencial",
		booking_activities: "Actividades App",
		name_place: "Nombre del sitio",
		direction_location: "Dirección url sitio",
		add_cost: "Añadir coste",
		add_field: "Añadir campo",
		review_gm_enjoy: "¿Los participantes se lo han pasado bien?",
		review_gm_size: "¿Cómo valoras el dimensionado del equipo de monitores?",
		review_gm_previous_info: "¿Cómo valoras la información previa a la actividad?",
		review_gm_started: "¿Cuándo ha empezado la actividad?",
		review_gm_material: "¿Se ha dañado o perdido algún material?",
		review_gm_comments: "¿Comentarios o incidencias?",
		review_expectations: "¿Hasta que punto nuestros servicios han cumplido tus expectativas?",
		review_scale: "Del 1 al 10, ¿qué tan probable es que recomiendes nuestros servicios a alguien de tu entorno? ",
		review_gamemasters: "¿Cómo calificarías la atención de los dinamizadores en la actividad?",
		review_content: "¿Cómo calificarías el contenido de la actividad?",
		review_service_prices: "¿Cómo calificarías el precio de los servicios?",
		review_previous_organization:"¿Cómo calificarías la organización previa a la actividad?",
		review_hire_again:"¿Cuáles son las probabilidades de que vuelvas a contratar nuestro servicio?",
		review_comments:"¿Tienes algún comentario o sugerencia para nosotros?",
		expected_not: "No han cumplido mis expectativas",
		expected_more: "Me esperaba más",
		expected_fulfilled: "Han cumplido con lo que esperaba",
		expected_exceeded: "Han superado mis expectativas",
		very_unsatisfactory: "Muy insatisfactoria",
		unsatisfactory:"Insatisfactoria",
		neutral: "Neutra",
		satisfactory: "Satisfactoria",
		very_satisfactory: "Muy satisfactoria",
		no_complaints: "No, han habido muchas quejas",
		standing_group: "No mucho, grupo parado",
		generally_yes: "Generalmente sí",
		highly_motivated: "Sí, grupo muy motivado",
		staff_shortage_critic: "Faltaba personal críticamente",
		staff_shortage:"Faltaba personal para ir cómodos",
		correct: "Correcto",
		staff_excess: "Sobraba personal",
		missing_information: "Faltaba información importante",
		missing_details: "Faltaban detalles",
		enough: "Suficiente",
		surplus: "De sobras",
		early_expected: "Más temprano de lo esperado",
		on_point:"En punto",
		ten_late:"0-10 min tarde",
		thirty_late: "10-30 min tarde",
		more_late: "+30 min tarde",
		user: "Usuario",
		timetable: "Horario",
		fundae: "FUNDAE" ,
		bill_sent: "Factura enviada",
		client_information: "Información del cliente",
		app: "App",
		event_details: "Detalles del evento",
		staff_persons: "Personal",
		incomes: "Ingresos",
		valoration_available: "VALORACIÓN (disponible al finalizar el evento)",
		very_likely: "Muy probable",
		likely: "Probable",
		unlikely: "Poco probable",
		very_unlikely: "Muy improbable",
		close_booking: "Cerrar Reserva",
		agency_files: "Archivos internos",
		client_files: "Archivos del cliente",
		add_file: "Añadir archivo",
		price_tag: "Tag precio",
		item: "Item de reserva",
		line: "Linea",
		pax: "Personas",
		auto_price: "Precio automático",
		done: "Terminadas",
		canceled: "Canceladas",
		open: "Abiertas",
		add_question_warning: "Las preguntas que añadas no se traducirán",
		place_contact: "Contacto del lugar",
		detail_canceled: "CANCELADA",
		detail_open: "ABIERTA", 
		detail_closed: "CERRADA",
	},
	activities: {
		activities: "Actividades",
		activity: "App",
		game: "App",
		day: "Dia",
		enter: "Entrar",
		create_activity: "Crear Actividad",
		sure: "Estas seguro?",
		delete: "Eliminar",
		date_hour: "Fecha y hora",
		name_activity: "Nombre de la actividad",
		type_activity: "App",
		language: "Idioma",
		comments: "Comentarios",
		logo: "Logo",
		demo: "Demo",
		alert_name_activity: "Pon un nombre al la actividad.",
		select_language: "Selecciona un idioma",
		select_challenge: "Selecciona algun reto",
		select_date: "Selecciona una fecha",
		select_activity: "Selecciona un tipo de actividad",
		select_name: "Pon un nombre a la actividad",
		select_primary_color: "Selecciona un color primario.",
		select_secondary_color: "Selecciona un color secundario.",
		save_error: "No se ha podido {message_request_negative} el evento",
		save_ok: "Evento {message_request} correctamente",
		delete_error: "No se ha podido eliminar el evento",
		delete_ok: "Se ha eliminado el evento",
		tl_activity: "Actividad Teaming Labs", 
		click_add: "Click para Añadir",
		dashboard: "Dashboard",
	},
	demos: {
		demos: "Demos",
		create_demo: "Crear Demo",
	},
	content: {
		content: "Contenido",
		list_content: "Lista de contenido",
		no_template:"Sin plantilla",
		all: "Todos",
		downloading_content: "Descargando contenido",
		ipad: {
			ipad_challenges: "Retos digitales",
			must: "Obligatorio",
			optional: "Opcional",
			first: "Primero",
			content_list: "Lista de Contenido",
			name: "Nombre",
			setting: "Configuración",
			type: "Plantilla",
			quiz: "Cuestionario",
			open_text: "Texto libre",
			photo: "Foto",
			video: "Video",
			alert: "Alerta",
			team_photo: "Foto de equipo",
			order: "Order",
			match: "Match",
			type_challenge: "Tipo de reto",
			id_name: "Nombre identificativo",
			password: "Constraseña",
			time: "Tiempo",
			max_points: "Puntos maximos",
			template:"Plantilla",
			description: "Descripción",
			file_1: "File 1",
			file_2: "File 2",
			file_3: "File 3",
			placeholder_type: "Selecciona un tipo",
			alert_type: "Selecciona un tipo de reto",
			placeholder_name_id: "Nombre identificador del reto...",
			alert_name_id: "Pon un nombre identificativo al reto.",
			placeholder_name: "Nombre del reto...",
			placeholder_password:"Contraseña...",
			placeholder_description: "Descripción del reto...",
			expected_answer: "Respuesta Esperada",
			placeholder_expected_answer: "Respuesta esperada",
			challenge: "Reto",
			placeholder_team: "Equipo..",
		},
		tapas: {
			tapas_challenges: "Retos culinarios",
			ingredients: "Ingredientes",
			item_shop: "Tienda",
			name: "Nombre",
			type: "Tipo",
			quiz: "Cuestionario",
			challenge: "Reto",
			type_challenge: "Tipo de reto",
			id_name: "Nombre Identificativo",
			money: "Dinero",
			template: "Plantilla",
			image: "Imagen",
			description: "Descripción",
			placeholder_type: "Selecciona un tipo",
			alert_type: "Selecciona un tipo de reto",
			placeholder_name_id: "Nombre identificador del reto...",
			alert_name_id: "Pon un nombre identificativo al reto.",
			placeholder_name: "Nombre del reto...",
			placeholder_description: "Descripción del reto...",
			setting: "Configuración",
			main_ingredient: "Ingrediente principal",
			secondary_ingredient: "Ingrediente secundario",
			decoration: "Decoración",
			base: "base",
			quantity: "Cantidad",
			quantity_placeholder: "Cantidad, Ej: 1 paquete 200gr...",
			objects_per_team: "Objetos por numero de equipos",
			object_shop: "Objeto Tienda"
		},
		utq: {
			challenges_utq: "Rally challenges",
			name: "Nombre",
			placeholder_name: "Nombre del reto...",
			id_name: "Nombre Identificativo",
			material: "Material",
			placeholder_material: "Material...",
			description: "Descripción",
			placeholder_description: "Descripción del  reto...",
			description_score: "Descripción Puntuación",
			placeholder_description_score: "Decripcion de la puntuación del reto...",
			image: "Imagen",
			quiz: "Cuestionario",
			challenge: "Reto",
			placeholder_name_id: "Nombre identificador del reto...",
			alert_name_id: "Pon un nombre identificativo al reto.",
		},
		action: {
			quiz: "Cuestionario",
			category_quiz: "Categoria Cuestionario",
			challenge: "Reto",
			challenges: "Retos",
			name: "Nombre",
			category: "Categoria",
			type: "Tipo",
			setting: "Template",
			init_challenge: "Reto inicial",
			init_challenges: "Retos iniciales",
			order: "Ordenación",
			match: "Emparejamiento",
			quiz_multi: "Cuestionario multirespuesta",
			text_auto: "Respuesta autocorregida",
			text_data: "Respuesta de texto",
			type_challenge: "Tipo de reto",
			placeholder_type: "Selecciona un tipo",
			alert_type: "Selecciona un tipo de reto",
			id_name: "Nombre Identificativo",
			placeholder_name_id: "Nombre identificador del reto...",
			alert_name_id: "Pon un nombre identificativo al reto.",
			placeholder_name: "Nombre del reto...",
			description: "Descripción",
			placeholder_description: "Descripción del reto...",
			image_quiz: "Imagen cuestionario",
			max_money: "Dinero maximo",
			need_material: "Necesita Material",
			material: "Material",
			placeholder_material: "Material necesario...",
			expected_answer: "Respuesta Esperada",
			placeholder_expected_answer: "Respuesta esperada",
			file_1: "File 1",
			file_2: "File 2",
			action_challenge_quiz: "Action Challenge Quiz",
			photo: "Foto",
			video: "Video",
			team_photo: "Foto equipo",
			template: "Plantilla",
			max_points: "Puntos maximos",
			cost: "Coste",
			video_duration: "Video duration",
			short_description: "Descripción corta",
			placeholder_short_description: "Descripción corta...",
			help_gm: "Ayuda para puntuar",
			help_file: "Imagen Solución",
			internal_description: "Descripción interna",
			placeholder_internal_description: "Descripción del reto interno...",
		},
		actionChallengeShopItem: {
			one_per_team: "Uno por equipo",
			two_per_team: "Dos por equipo",
			one_per_two_teams: "Uno cada dos equipos",
			one_per_three_teams: "Uno para tres equipos",
			identifying_name: "Nombre Identificativo",
			identifying_name_placeholder: "Nombre identificador del cuestionario...",
			identifying_name_alert: "Pon un nombre identificativo al cuestionario.",
			image: "Imagen",
			cost: "Coste",
			max_per_team: "Máximos por equipo",
			template: "Plantilla",
			default_material: "Material por defecto",
			material_description: "Descripción del material",
			name: "Nombre",
			name_placeholder: "Nombre del reto...",
			description: "Descripción",
			description_placeholder: "Descripción del reto..."
		},
	},
	settings: {
		settings: "Plantillas",
		settings_list: "Lista de plantillas",
		ipad: {
			name: "Nombre",
			id_name: "Nombre Identificativo",
			placeholder_name_id: "Nombre identificador del reto...",
			alert_name_id: "Pon un nombre identificativo al reto.",
			main_color: "Color principal",
			secondary_color: "Color secundario",
			stopwatch_color: "Color cronómetro",
			background_image: "Imagen de fondo",
			points_icon: "Icono de puntos",
			challenges: "Retos",
			challenge: "Plantilla"
		},
		tapas: {
			name: "Nombre",
			id_name: "Nombre Identificativo",
			placeholder_name_id: "Nombre identificador del reto...",
			alert_name_id: "Pon un nombre identificativo al reto.",
			secondary: "Secundarios",
			decoration: "Decoración",
			processing_time: "Tiempo de elaboración",
			ingredients: "Ingredientes",
			item_shop: "Tienda",
			setting: "Plantilla"
		},
		action: {
			name: "Nombre",
			id_name: "Nombre Identificativo",
			placeholder_name_id: "Nombre identificador del reto...",
			alert_name_id: "Pon un nombre identificativo al reto.",
			challenges: "Retos",
			categories: "Categorias",
			challenge: "Plantilla",
			game_mode: "Modo de juego"
		},
	},
	erp_settings: {
		link_app: "Link App",
		organization: "Organización",
		name: "Nombre",
		placeholder_name: "Nombre",
		alert_name: "Debe tener un nombre",
		logo: "Logo",
		billing: "Facturación",
		placeholder_billing: "Datos de facturación...",
		list_activities: "Lista de Actividades",
		new_activity: "Nueva Actividad",
		search: "Buscar",
		placeholder_search: "Buscar por nombre",
		in_person: "presencial",
		online: "Online",
		modality: "Modalidad",
		alert_modality: "Selecciona una modalidad",
		general_media:"Media",
		pricing_list: "Lista de precios",
		activity_name: "Nombre",
		placeholder_activity_name: "Nombre de la actividad (Cliente)",
		duration: "Duración",
		placeholder_duration: "Duracion de la actividad",
		description: "Descripción",
		placeholder_description: "Descipcion de la actividad",
		requirements: "Requerimientos",
		placeholder_requirements: "Requirimentos de la actividad",
		includes: "Incluye",
		placeholder_includes: "Que incluye la actividad",
		dynamic: "Dinámica",
		placeholder_dynamic: "Dinámica de la actividad",
		budget_description: "Descripción del presupuesto",
		placeholder_budget_description: "Descripción del presupuesto",
		media: "Media en este idioma",
		extras: "Extras",
		price: "Precio",
		activity: "Actividad",
		type_price:"Tipo de precio",
		alert_type_price: "Seleciona un tipo de precio",
		new_extra: "Nuevo Extra",
		preview_pdf: "Previsualizar PDF",
		extra_deleted: "Se ha eliminado el extra",
		extra_not_deleted: "No se ha eliminado el extra",
		placeholder_name_extra: "Nombre del extra",
		placeholder_description_extra: "Descripción del extra",
		extra: "Extra",
		total: "Total",
		by_person: "Por Persona",
		final_page_budget:"Página final de presupuesto",
		new_final_page_budget:"Nueva página final de presupuesto",
		final_page_budget_deleted: "Se ha eliminado la página final de presupuesto",
		final_page_budget_not_deleted: "No se ha podido eliminar la página final de presupuesto",
		placeholder_description_activity: "Descripcion de la actividad",
		question: "Pregunta",
		placeholder_question: "Pregunta",
		all_activities: "Todas las actividades",
		no_activity: "Sin actividad",
		placeholder_activity: "Filtrar por actividad",
		questions_activities: "Preguntas sobre actividades",
		code: "Código",
		placeholder_code: "Codigo de la actividad",
		question_booking: "Preguntas sobre reservas",
		new_question_booking: "Nueva pregunta sobre reservas",
	},
	previews: {
		menu: "Menu",

	},
	login: {
		description: "Aplicación de Teaming Labs para la realización de eventos de Team Building",
		email: "Email",
		placeholder_email: "Entra tu email",
		password: "Contraseña",
		placeholder_password: "At least 8 characters",
		sign_in: "Iniciar Sesión",
		forgor_password: "Contraseña olvidada",
	},
	formGenerator: {
		pricing: {
			new_price: "+ Nueva linea de precio",
			min_persons: "Personas minimo",
			max_persons: "Personas maximo",
			total_price: "Precio total",
			person_price: "Precio persona",
			price: "Precio ",
		}
	},
	dialogs: {
		sure: "Estas seguro?",
		erase: "Borrar",
		erase_record: "Borrar registro",
		delete_team: "Eliminar equipo",
		delete: "Eliminar",
		delete_colon: "Eliminar: ",
		cancel: "Cancelar",
		name: "Nombre",
		add: "Añadir",
		placeholder_team: "Equipo..",
		edit_team: "Editar equipo",
		save: "Guardar",
		demo_limit: "Las actividades de demo solo permiten 2 equipos",
		link_staff: "Enlace Staff",
		link_admin: "Enlace Administrador",
		link_results: "Enlace Resultados",
		go_results: "Ir a resultados",
		add_team: "Añadir equipo",
		material_recalculate: "Al crear el equipo se recalculara el material",
		warning: "Aviso",
		add_team_money: "Añadir dinero equipo",
		link_teams: "Enlace Equipos",
		challenge_delete: "Se elminiarla el reto: ",
		create: "Crear",
		challenge: "Reto",
		placeholder_challenge: "Selecciona un reto",
		warning_change_template: "Al cambiar la plantilla se borraran todas los retos",
		team: "Equipo",
		points_negative: "Puntos (pueden ser negativos)",
		money_negative: "Dinero (puede ser negativo)",
		minutes: "Minutos",
		seconds: "Segundos",
		substract: "Restar",
		expected_answer:"Respuesta esperada: ",
		challenge_score: "Puntuar reto",
		activate: "Activar",
		material_recalculate_delete: "Al eliminar el equipo se recalculara el material",
		add_route: "Añadir ruta",
		confirm: "Confirmar",
		accept: "Aceptar",
		activity: "Actividad",
		language: "Idioma",
		placeholder_language: "Selecciona un idioma",
		logo: "Logo",
		sure_delete_acitivty: "Estas seguro que quieres borrar al actividad?",
		add_favorite: "Añadir a favoritos",
		delete_favorite: "Quitar de favoritos",
		date: "Fecha",
		unsaved_changes_content:"Estas seguro?",
		unsaved_changes_title:"Se perderan los cambios no guardados",
		stay:"Quedarse",
		exit:"Salir",
		client_validate: "Se necesita validar datos de cliente",
		close_booking: "Cerrar reserva",
		cancel_reason: "Motivo de la cancelación",
		finish: "Finalizar",
	},
	// CAMERA 
	// TODO TEXT
	camera:{
		title_error_modal:"Error en la cámara",
		text_error_modal:"Avisar a la persona de staff para revisar la cámara. Error: ",
		btn_reload_app:"Recargar App",
		btn_load_foto:"Subir foto desde la galería",

		modal_upload_title:"Subir Foto",
		modal_upload_text:"Puedes subir tu foto desde la galería",

		error_no_upload:"Tienes que subir una foto primero",
		error_upload_photo:"Error al subir la foto, vuelve a intentarlo",
	},
	
	// TO CHECK
	chat:{
		message:"Mensaje",
		message_placeholder:"Escribe tu mensaje",
		message_btn:"Enviar mensaje",
		send:"Enviado",
		read:"Leído"
	},
	gm: {
		modals: {
			add_monitor: "Añadir monitor",
			save: "Guardar",
			name: "Nombre",
			placeholder_name: "Nombre Monitor", 
			saved_monitor: "Monitor guardado",
			continue: "Continuar",
			change: "Cambiar",
			exit: "Salir",
			cancel:"Cancelar",
			exit_event: "Salir del evento",
			password: "Contraseña",

		},
		dashboards: {
			configuration: "Configuración de actividad",
			link_ranking: "Enlace Ranking",
			link_results: "Enlace Resultados",
			link_monitors: "Enlace Monitores",
			link_admin: "Enlace Admin",
			link_presentation: "Enlace Presentación",
			link_teams: "Enlace Equipos",
			list_ingredients: "Lista de ingredientes",
			link_favorite_images: "Enlace Imagenes favoritas",
			total: "Total",
			remaining: "Restante",
			round_ends: "Ronda finaliza",
			round: "Ronda",
			play: "Jugar",
			resume: "Continuar",
			pause: "Pausar",
			pass_round: "Saltar Ronda",
			time_ranking: "Ver tiempo en ranking",
			results: "Resultados",
			add_team: "Añadir Equipo",
			add_teams: "Añadir Equipos",
			order_points: "Ordenar por puntos",
			add_challenges_first: "Añade retos primero",
			general: "General",
			material: "Material",
			event_configuration: "Configura el evento primero",
			back: "Atrás",
			save: "Guardar",
			challenges: "Retos",
			score_categories: "Categorias de puntuación",
			defense: "DEFENSA",
			presentation: "PRESENTACIÓN",
			flavor: "SABOR",
			money: "DINERO",
			round_time: "Tiempo por ronda (segundos)",
			tapas_time: "Tiempo de cocina (segundos)",
			type: "Plantilla",
			placeholder_type: "Selecciona un tipo",
			ingredient: "Ingredientes",
			shop: "Tienda",
			select_challenge: "Selecciona algun reto",
			select_activity: "Selecciona una actividad",
			select_time_round: "Pon un tiempo por ronda",
			select_time_tapas: "Pon un tiempo para realizar las tapas",
			select_categories: "Porfavor selecciona las categorias",
			team_turn: "Turno del equipo",
			type_election: "Tipo de elección",
			round_election: "Ronda de elección",
			round_total: "Ronda total",
			time_elaboration: "Tiempo de elaboración",
			name_team: "Ponle un nombre al equipo",
			number_teams: "Numero de equipos",
			total_ingredients: "Total de ingredientes",
			extra_ingredients: "Ingredientes extras",
			total_decoration: "Total decoración",
			extra_decoration: "Decoración extra",
			missing_ingredients: "Faltan ingredientes",
			list_download: "Descargar Lista",
			ingredients: "Ingredientes",
			saved: "Guardado",
			start_game: "Comenzar Juego",
			gm: "Monitor",
			readed: "Leido",
			sent: "Enviado",
			message_team: "Escribe un mensaje al equipo ",
			message_all_teams: "Los mensajes que escribas se enviaran a todos los equipos",
			your_message: "Tu mensaje",
			upload_image: "Subir imagen",
			add_emoji: "Añadir emoticono",
			send_message: "Enviar mensaje",
			all: "Todos",
			challenge: "Reto",
			id_name: "Nombre identificativo",
			latitude: "Latitud",
			longitude: "Longitud",
			activations_radius: "Radio de activación",
			categories_points: "Categorias de puntuación",
			must_upper: "OBLIGATORIA",
			first_upper: "PRIMER",
			type_activation: "Tipo de activación",
			qr_upper: "QR",
			location_upper: "UBICACIÓN",
			first: "Primera",
			must: "Obligatoria",
			add_challenges:"Añade retos",
			select_latitude: "Selecciona una latitud",
			select_longitude: "Selecciona una longitud",
			placeholder_id_name: "Pon un nombre identificativo",
			placeholder_template: "Selecciona una plantilla",
			put_duration: "Pon una duración",
			initial_position: "Posición inicial",
			not_template: "Sin plantilla",
			template: "Plantilla",
			placeholder_search_address: "Busca una dirección...",
			duration_seconds: "Duración (segundos)",
			duration_hours: "Duración Horas",
			duration_minutes: "Duración Minutos",
			categories: "Categorias",
			time_action: "Pon un tiempo para realizar los retos",
			routes: "Rutas",
			team_no_route: "Hay equipos sin ruta",
			changes_not_saved: "Cambios no guardados",
			route: "Ruta",
			order: "Orden: ",
			name_route: "Nombre",
			placeholder_name_route: "Nombre de la ruta",
			event: "Evento",
			select_setting: "Selecciona una configuración",
			initial_challenges: "Retos Iniciales",
			time_higher_zero: "El tiempo debe ser mayor que 0",
			time_required: "El tiempo por ronda es requerido",
			time_integer: "El tiempo tiene que ser un entero",
			warning_template: "Si no eliges una plantilla tendras que crear todos los retos manualmente.",
			add_teams_first: "Primero tienes añadir equipos, antes de añadir material",
		},
		utq: {
			change_monitor: "Cambiar Monitor",
			round: "Ronda",
			ends: "Finaliza",
			start: "Empezar",
			team_upper: "EQUIPO",
			position_upper: "POSICIÓN",
			points_upper: "PUNTOS",
			team: "Equipo",
			placeholder_team: "Selecciona un equipo",
			score: "Puntuación",
			rate: "Puntuar",
			instructions_material: "Instrucciones y material",
			name_event: "Pon un nombre al evento",
			select_team: "Selecciona un equipo",
			operation: "Funcionamiento",
			material: "Material",
		},
		tapas: {
			round_ends: "Ronda finaliza",
			score_missing: "Quedan retos sin puntuar a los equipos",
			start_challenges: "Empezar Retos",
			start_selection: "Empezar Selección Ingredientes",
			start_elaboration: "Empezar Tiempo de Elaboración",
			pass_round: "Saltar Ronda",
			selecting_ingredients: "Seleccionando ingredientes",
			pass_selection: "Saltar selección",
			team_turn: "Turno del equipo",
			type_selection: "Tipo de selección",
			round_selection: "Ronda de selección",
			round_total: "Ronda total",
			votes: "Votaciones",
			type_choice: "Tipo de elección",
			top_1: "Top 1",
			top_2: "Top 2",
			top_3: "Top 3",
			round: "Ronda",
			time_eleboration: "Tiempo de elaboración",
			start:"Empezar",
			challenges: "Retos",
			placeholder_challenge: "Selecciona un reto y pulsa empezar",
			orders: "Pedidos",
			change_money: "Modificar dinero",
			shop: "Tienda",
			challenges_results: "Resultados retos",
			ingredient_selection: "Selección de ingredientes",
			winners: "Ganadores",
			placeholder_select_team: "Selecciona un equipo",
			category: "Categoria",
			rate: "Puntuar",
			defense: "Defensa",
			show: "Presentación",
			flavour:"Sabor",
		},
		ipad: {
			time_game: "Tiempo de juego",
			start_game: "Comenzar Juego",
			add_delete_time: "Añadir/Quitar Tiempo",
			hide_ranking: "Esconder Ranking",
			show_ranking: "Enseñar Ranking",
			lock_challenges:"Bloquear Retos",
			unlock_challenges: "Desbloquear Retos",
			challenges: "Retos",
			clean_filters: "Limpiar filtros",
			add_delete_points: "Añadir/Quitar Puntos",
			activate_challenge: "Activar reto",
			no_route: "Sin Ruta",
			instructor: "Monitor",
			player: "Jugador",
			team: "Equipo",
			points: "Puntos",
			challenges_done: "Retos Realizados",
			last_challenge: "Ultimo Reto",
			hour: "Hora",
			must: "Obligatorios",
			optionals: "Opcionales",
		},
		action: {
			start:"Empezar",
			next: "Siguiente",
			time_game: "Tiempo de juego",
			start_game: "Comenzar Juego",
			add_delete_time: "Añadir/Quitar Tiempo",
			ranking: "Ranking",
			finish_game: "Finalizar Juego",
			reactivate_game: "Reactivar Juego",
			challenges: "Retos",
			clean_filters: "Limpiar filtros",
			team_filter: "Filtro Equipo",
			challenge_filter: "Filtro Reto",
			add_delete_points: "Añadir/Quitar Puntos",
			add_delete_money: "Añadir/Quitar Dinero",
			activate_quiz: "Activar Cuestionario",
			ranking_normal: "Normal",
			ranking_coop: "Cooperativo",
			ranking_both: "Ambos",
			ranking_hidden: "Escondido",
			activate_challenge: "Activar reto",
			quizzes: "Cuestionarios",
			challenge_not_start: "Todavia no ha comenzado ningun reto",
			challenges_pick: "Retos a recoger",
			challenges_return: "Retos a devolver",
			challenges_material: "Material retos",
			update_stock: "Modificar Stock",
			stock_upper: "STOCK: ",
			stock: "Stock",
			team_upper: "EQUIPO",
			challenge_upper: "RETO",
			returned_upper: "DEVUELTO",
			delivered_upper: "ENTREGADO",
			team: "Equipo",
			points: "Puntos",
			money: "Dinero",
			quizzes_done: "Cuestionarios Realizados",
			challenges_done: "Retos Realizados",
			last_challenge: "Ultimo Reto",
			material: "Material",
			save: "Guardar"
		},
	},
	messages: {
		round_pass: "Ronda saltada",
		canceled: "Cancelado",
		deleted: "Eliminado",
		updated: "Actualizado",
		added: "añadido",
		score_ok: "Puntuación enviada correctamente",
		score_not_ok: "No se pudo enviar la puntuación",
		score_add: "Pon una puntuación",
		select_team: "Selecciona un equipo",
		team_error: "No se ha podido añadir el equipo",
		team_correctly: "Equipo {msg} correctamente",
		incorrect_fields: "Hay campos incorrectos en el formulario",
		tapas_succesfull_updated: "Actividad Guerra de Tapas actualizada correctamente",
		tapas_not_succesfull_updated: "No se ha podido actualizar la actividad Guerra de Tapas",
		tapas_succesfull_added: "Actividad Guerra de Tapas añadida correctamente",
		tapas_not_succesfull_added: "No se ha podido añadir la actividad Guerra de Tapas",
		select_passed: "Selección saltada",
		link_copied: "Enlace copiado",
		votes_deleted: "Votos eliminados correctamente",
		votes_not_deleted: "No se han podido eliminar los votos",
		money_updated: "Dinero actualizado",
		teams_added: "Equipos añadidos correctamente",
		game_started: "Juego Empezado",
		deleted_ok: "Eliminado correctamente",
		deleted_not_ok: "No se ha podido elminiar",
		updated_not_ok: "No se ha podido actualizar",
		select_challenge: "Selecciona un reto",
		challenges: "Retos",
		template: "Plantilla",
		ipad_hunt_not_succesfull_added: "No se ha podido añadir la actividad Ipad Hunt",
		challenge_activated: "Reto Activado",
		points_updated: "Puntos Actualizados",
		time_updated: "Tiempo Actualizado",
		correctly_scored:"Puntuado correctamente",
		incorrectly_scored:"No se ha podido puntuar correctamente",
		team: "Equipo",
		action_updated_ok: "Actividad Action Challenge actualizada correctamente",
		action_updated_not_ok: "No se ha podido actualizar la actividad Action Challenge",
		action_added_ok: "Actividad Action Challenge añadida correctamente",
		action_added_not_ok: "No se ha podido añadir la actividad Action Challenge",
		route_created: "Ruta Creada",
		route_deleted: "Ruta Borrada",
		route_saved: "Ruta Guardada",
		saved: "Guardado",
		select_category: "Escoger una categoria",
		must_select: "Debes selecionar top1 y top 2",
		vote_sent: "Votación enviada",
		vote_error: "Error al votar",
		correct_score: "Puntuado Correctamente",
		incorrect_score: "No se ha podido puntuar correctamente",
		game_reactivated: "Juego Reactivado",
		game_finished: "Juego Finalizado",
		write_quesiton: "Escribe una pregunta",
		event_deleted: "Evento borrado",
		select_activity: "Debes selecionar la actividad",
		event_created: "Evento creado",
		event_updated: "Evento editado",
		event_not_created: "No se ha podido crear el evento",
		utq_updated: "Actividad Rally actualizada correctamente",
		utq_not_updated: "No se ha podido actualizar la actividad Rally",
		url_copied: "URL copiada",
		url_error: "Error al copiar la URL",
		error_deleting_event: "No se ha podido eliminar el evento",
		reopened: "Reabierto",
		finished: "Finalizado",
		cancelled: "Cancelado",
		closed_booking: "Reserva Cerrada",
		canceled_booking: "Reserva Cancelada",
		file_removed: "Archivo eliminado",
		file_uploaded: "Archivo subido",
		error_upload: "No se ha podido subir el archivo",
		no_id_model:"Hay que guardar el formulario antes de subir archivos",
	},
	player_views: {
		event:{

		},
		home: {
			user_registered: "Usuario registrado",
			active_events: "Ver eventos activos",
			insert_code: "Introducir código evento // equipo",
			qr_scan: "Escanear QR",
			code_event: "Código evento",
			code_team: "Código equipo",
			event_not_exist: "El evento no existe",
			team_not_exist: "El equipo no existe"
		},
		player_view: {

		}
	},
	public_views: {
		tapas: {
			presentation_tapas: {
				end: "Fin de la presentación"
			},
			ranking_tapas: {
				round_ends: "Ronda finaliza",
				team_turn: "Turno equipo",
				choice_type: "Tipo elección",
				choice_round: "Ronda elección",
				total_round: "Ronda total",
				time_preparation: "Tiempo de elaboración",
			},
			results_tapas: {

			}
		},
		utq: {
			ranking_utq: {
				round_ends: "Ronda finaliza"
			},
			results_utq: {

			},
		}
	},
	// TO REVISE
	ipadHunt:{
		ipadHunt_player:{
			must_challenge:"Reto obligatorio",
			modal_password:"Escribe la contraseña que te proporcionará el miembro de staff",
			modal_error_download_title:"Contenido no guardado",
			modal_error_download_text:"Volver a descargar el contenido",
			modal_download_content_title:"Descargando contenido",
			modal_download_content_text:"Descargando contenido",
			modal_error_location_title:"No está disponible la localización",
			modal_error_location_text:"Activa los permisos de localización o ponte en contacto con el organizador del evento.",
			
			// MENU OPCIONES
			content_correct:"Contenido descargado correctamente",
			content_incorrect:"Hay errores en el contenido, volver a descargar",
			delete_content:"Borrar el contenido y volver a descargar",
			test_camera:"Probar cámara",

			// CHALLENGE
			answer_send:"Vuestra respuesta se ha registrado correctamente. En los próximos minutos recibiréis los puntos correspondientes.",
			match:"Relacionar",
			take_photo:"Hacer foto",
			take_video:"Grabar video",

			// ERRORS
			error_wrong_password:"La contraseña es incorrecta",
			error_no_answer:"No se ha detectado ninguna respuesta",
			error_order:"Tienes que ordenar las respuestas",

			// MODAL TESTING
			modal_test_title:"TEST",
			modal_test_localization_correct:"Localización correcta",
			modal_test_localization_incorrect:"La localización no está disponible",
			modal_test_ok:"Test correcto"

			
		},
		game_master_ranking: {
			name: "Nombre",
			points: "Puntos",
			challenges_done: "Retos Realizados",
			last_challenge: "Último reto"
		}
	},
	actionChallenge: {
		actionChallenge_player: {
			audio_error: "Tu navegador no soporta el formato de audio.",
			need_material: "En este reto necesitas material",
			continue: "CONTINUAR",
			send_response: "ENVIAR RESPUESTA",
			correct_response: "RESPUESTA CORRECTA",
			correct_responses: "RESPUESTAS CORRECTAS",
			incorrect_response: "RESPUESTA INCORRECTA",
			money_won: "Dinero ganado:",
			response: "Respuesta",
			btn_match: "Match",
			successes: "Aciertos",
			similarity: "La palabra coincide un {similarity_percentage}% a las palabras correctas",
			category_no_elements: "No hay elementos en esta categoría",
			not_started: "EL EVENTO TODAVÍA NO HA EMPEZADO",
			challenge_warning:"Tras comprar un reto, ve a buscar el material a la tienda para completarlo.",
			pick_material:"VE A BUSCAR EL MATERIAL A LA TIENDA PARA EMPEZAR ESTE RETO",
			challenge_send:"LA RESPUESTA HA SIDO ENVIADA Y SERÁ EVALUADA POR EL MONITOR",
			return_material:"DEVUELVE EL MATERIAL A LA TIENDA PARA SELECCIONAR EL SIGUIENTE RETO",
			points_won: "Puntos ganados",
			wrong_answer: "RESPUESTA INCORRECTA",
			game_finished: "LA PARTIDA HA FINALIZADO",
			return_material_finish:"DEVUELVE EL MATERIAL DEL RETO A LA TIENDA",
			no_material: "En estos momentos no hay material suficiente para hacer este reto",
			no_money: "No tienes dinero suficiente para comprar este reto",
			shop:"Tienda",
			obejcts_buy:"Material comprado",
			quantity:"Cantidad",
			modal_buy_text:"Comprar {object} por {dinero}$",
			// material
			error_item_buy_no_money:"No tienes suficiente dinero",
			error_item_buy_no_stock:"No queda stock",
			error_item_buy_can_not_more:"No puedes compar más material de este",
			ok_item_buy:"Material comprado, ya puedes recogerlo",
			construction_name:"Nombre de la construcción",
			construction_photo:"Foto de la construcción"


		}
	},
	tapas: {
		tapas_player: {
			change_name: "Cambiar nombre",
			time_choose: "Tiempo para escoger",
			presentation_in: "Presentación en",
			final_presentation: "Presentación final",
			dish_name_PC: "Nombre de la paella",
			dish_name_Tapas: "Nombre de la tapa",
			dish_name_MS: "Nombre de la sangría",
			team_photo: "Foto de equipo",
			dish_photo_PC: "Foto de la paella",
			dish_photo_Tapas: "Foto de la tapa",
			dish_photo_MS: "Foto de la sangría",
			available_money: "Dinero disponible",
			choose_ingredient: "Escoger ingrediente",
			team_choosing: "Equipo eligiendo",
			choose_rice: "Escoger arroces",
			choose_plates: "Escoger platos",
			choose_decoration: "Escoger decoración",
			change_team_name: "Cambiar nombre de equipo",
			buy_confirm: "Confirmar compra",
			meal_photo: "Foto de la comida",
			exit_event: "Salir del evento",
			answer_correct: "La respuesta es correcta",
			answer_incorrect: "La respuesta es incorrecta",
			name_changed: "Nombre cambiado",
			name_sent: "Nombre enviado",
			item_purhased: "Objeto comprado, ya puedes ir a recogerlo en la tienda",
			no_unit: "No hay unidades",
			password_incorrect: "La contraseña no es correcta",
			item_purchased: "Utensilio comprado"
		}
	},
	modals: {
		upload_photo: {
			change_camera: "Cambiar cámara",
			upload_photo: "Subir foto",
			photo_success:"Foto enviada",
		}
	},
	stores: {
		tapas: {
			main_ingredient: "Ingrediente principal",
			global_winner: "Global",
			flavour_winner: "Sabor",
			presentation_winner: "Present.",
			defense_winner: "Defensa",
			money_winner: "Dinero",
			photo_tapa: "F. TAPA",
			photo_team: "F. EQUIPO",
			tapa: "TAPA",
			position: "POSICIÓN",
			points:"PUNTOS",
		}
	},
	erp: {
		booking: {
			reservation: "Reserva",
			client_link: "Link cliente",
			changes_pending: "Cambios pendientes de validar",
			calendar: {
				calendar: "Calendario",
				production_details: "Detalles producción",
				pax: "Pax",
				language: "Idioma",
				fundae: "FUNDAE",
				staff_number: "Número de monitores",
				teams_number: "Número de equipos",
				bookings: 'Reservas',
				budgets: 'Presupuestos'
			}
		},
		user_settings: {
			title: "Configuración de Usuario",
			change_password: "Cambiar Contraseña",
			new_password: "Nueva Contraseña",
			enter_new_password: "Ingrese la nueva contraseña",
			confirm_password: "Confirmar Contraseña",
			confirm_new_password: "Confirme la nueva contraseña",
			change_password_button: "Cambiar Contraseña",
			change_language: "Cambiar Idioma",
			language: "Idioma",
			select_language: "Seleccione un idioma",
			change_language_button: "Cambiar Idioma",
			password_required: "Por favor ingrese la nueva contraseña",
			password_min_length: "La contraseña debe tener al menos 8 caracteres",
			confirm_password_required: "Por favor confirme la nueva contraseña",
			passwords_not_match: "Las contraseñas no coinciden",
			password_changed_success: "Contraseña cambiada exitosamente.",
			password_change_error: "Error al cambiar la contraseña. Por favor, inténtelo de nuevo.",
			form_errors: "Por favor, corrija los errores en el formulario.",
			spanish: "Español",
			english: "Inglés",
			language_required: "Por favor seleccione un idioma",
			language_updated_success: "Idioma actualizado exitosamente.",
			language_update_error: "Error al actualizar el idioma. Por favor, inténtelo de nuevo.",
			select_valid_language: "Por favor, seleccione un idioma válido."
		},
		configuration: {
			organization: {
				link_app: "Enlace de la aplicación",
				organization_url: "URL de la organización",
				copy: "Copiar",
				users: "Usuarios",
				create_new_user: "Crear Nuevo Usuario",
				edit_user: "Editar Usuario",
				name: "Nombre",
				enter_name: "Ingrese el nombre",
				role: "Rol",
				cancel: "Cancelar",
				save: "Guardar",
				email: "Email",
				enter_email: "Ingrese el email",
				password: "Contraseña",
				enter_password: "Ingrese la contraseña",
				confirm_password: "Confirmar Contraseña",
				confirm_password_placeholder: "Confirme la contraseña",
				create: "Crear",
				confirm_deletion: "Confirmar Eliminación",
				delete_user_confirmation: "¿Está seguro que desea eliminar al usuario {name}?",
				delete: "Eliminar",
				organization_qr_code: "Código QR de la Organización",
				administrator: "Administrador",
				facilitator: "Monitor",
				edit: "Editar",
				actions: "Acciones",
				url_copied: "URL copiada al portapapeles",
				user_updated: "Usuario actualizado exitosamente",
				error_updating_user: "Error al actualizar el usuario",
				user_created: "Usuario creado exitosamente",
				error_creating_user: "Error al crear el usuario",
				user_deleted: "Usuario eliminado exitosamente",
				error_deleting_user: "Error al eliminar el usuario",
				select_role: "Seleccione un rol"
			}
		}
	},
	events: {
		active_events: "Eventos activos",
		event: "Actividad",
		game: "App",
		day: "Fecha",
		actions: "Acciones",
		view_event: "Ver actividad",
		enter: "Entrar",
		finish: "Finalizar",
		reopen: "Reabrir",
		delete_event: "Eliminar evento",
		are_you_sure: "¿Estás seguro?",
		accept: "Aceptar",
		cancel: "Cancelar",
		no_content: "No hay contenido",
		edit: "Editar",
		download: "Descargar",
		delete: "Eliminar"
	},
	client: {
		budgets: "Presupuestos",
		bookings: "Reservas",
		billed: "Facturado",
	}
}